<template>
  <div class="fragment h-100 d-flex direction-column">

    <div class="custom-row">
<!--      <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xl-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['shops_sellerID'])"></span>-->
<!--        <DefaultInput-->
<!--                class="white"-->
<!--                v-bind:class="{'ui-no-valid': dataValidate.validation.sellerId}"-->
<!--                :error="dataValidate.validation.sellerId"-->
<!--                :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.sellerId : $t(`${dataValidate.validationTranslate.sellerId}.localization_value.value`)"-->
<!--                :type="'text'"-->
<!--                :label="$t('shops_sellerID.localization_value.value')"-->
<!--                v-model="data.sellerId"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;xl-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['shops_amazonMWSToken'])"></span>-->
<!--        <DefaultInput-->
<!--                class="white"-->
<!--                v-bind:class="{'ui-no-valid': dataValidate.validation.amazonToken}"-->
<!--                :error="dataValidate.validation.amazonToken"-->
<!--                :errorTxt="dataValidate.serverError ? dataValidate.validationTxt.amazonToken : $t(`${dataValidate.validationTranslate.amazonToken}.localization_value.value`)"-->
<!--                :type="'text'"-->
<!--                :label="$t('shops_amazonMWSToken.localization_value.value')"-->
<!--                v-model="data.amazonToken"-->
<!--        />-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>

  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "AmazonShop",
    components: {
      // DefaultInput,
    },

    props:['shopAmazon', 'validate'],

    data(){
      return {
        data: this.shopAmazon,
        dataValidate: this.validate
      }
    },

    watch: {
      validate(validate) {
        this.dataValidate = validate
      },
    },

    methods: {
      changeData() {
        this.$emit('input', this.data)
      }
    }
  }
</script>

<style lang="scss">

</style>
